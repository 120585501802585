/**
 * @file 判读一下浏览器运行的操作系统
 * @author WanZhongYun(wanzhongyun@baidu.com)
 */

export const getOsType = () => {

    const ua = navigator.userAgent.toLowerCase();
    const testUa = (regexp: RegExp) => regexp.test(ua);

    let system = "unknow";
    if (testUa(/windows|win32|win64|wow32|wow64/g)) {
        system = "windows";
    } else if (testUa(/macintosh|macintel/g)) {
        system = "macos";
    } else if (testUa(/x11/g)) {
        system = "linux";
    } else {
        system = "otheros";
    }
    
    return system;
}