/**
 * @file paddle 首页 图标文字列表
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, {useMemo} from 'react';
import {Row} from 'antd';
import {IIconTextCard} from '../../types';
import IconTextCardItem from './IconTextCardItem';

const IconTextCard: React.FC<IIconTextCard> = React.memo(props => {
    const list = props.list;
    const xs = useMemo(() => {
        const theList = list || [];
        if (theList.length === 4) {
            return 6;
        }
        else if (theList.length === 3) {
            return 8;
        }
        else if (theList.length === 2) {
            return 12;
        }
        else if (theList.length === 1) {
            return 24;
        }
        return 8;
    }, [list]);

    return (
        <Row className="paddle-index-icon-text-card">
            {props.list.map(item => (
                <IconTextCardItem
                    key={`${item.title}${item.text}`}
                    {...item}
                    xs={xs}
                />
            ))}
        </Row>
    );
});

export default IconTextCard;
