/**
 * @file paddle 首页 图标文字列表 在线体验
 * @author FengGuang(fengguang01@baidu.com)
 */

import React from 'react';
import {RightOutlined} from '@ant-design/icons';
import {IIconTextListItem} from '../../types';
import A from '../../../../components/a/A';

const IconTextListItem: React.FC<IIconTextListItem> = props => {
    return (
        <A
            className="paddle-index-icon-text-list-item"
            href={props.link}
            target={props.target ? '_blank' : undefined}
            rel={props.target ? 'noopener noreferrer' : undefined}
        >
            <i
                className="paddle-index-icon-text-list-item-icon"
                style={{backgroundImage: props.iconUrl ? `url(${props.iconUrl})` : undefined}}
            />
            <div className="paddle-index-icon-text-list-item-title">
                {props.title}
            </div>
            <div className="paddle-index-icon-text-list-item-tag">
                {props.text} {!!props.text && <RightOutlined />} &nbsp;
            </div>
        </A>
    );
};

export default IconTextListItem;
