/**
 * @file 快速开始组件的 store
 * @author FengGuang(fengguang01@baidu.com)
 */

import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useLatest} from 'react-use';

import {
    IQuickInstallSelectorGroup,
    IQuickInstallItem, IQuickInstallListCache
} from './type.d';
import React from 'react';


// 更新选项的禁用状态
// 阻止用户做出某种选择使得结果为空。
// 本方法会遍历 quickInstallList ，根据已选项将部分未选项禁用，
// 这个过程可以看做是一种搜索过滤的过程，根据用户的选择过滤出 quickInstallList 中的一些数据
const updateDisabledState = (
    checked: string[],
    quickInstallSelector: IQuickInstallSelectorGroup[],
    quickInstallList: IQuickInstallItem[],
    checkedQuickInstallListCache: { [key: string]: IQuickInstallItem[] }
): { disabled: string[], checkedItem: IQuickInstallItem[] } => {
    console.log('updateDisabledState start');
    console.log(checked);
    console.log(quickInstallSelector);
    console.log(quickInstallList);
    console.log(checkedQuickInstallListCache);
    const newDisabledSet: Set<string> = new Set();
    // 遍历 quickInstallSelector 获取所有出现过的 optionKey
    quickInstallSelector.forEach(group => {
        group.list.forEach(item => {
            newDisabledSet.add(item.optionKey);
        });
    });
    console.log('set newDisabledSet');
    console.log(newDisabledSet);
    // 当前选中状态对应的搜索结果
    const checkedQuickInstallList = (() => {
        console.log('checkedQuickInstallList start');
        console.log(checked);
        const checkedIndex = checked.sort().join(',');
        console.log(checkedQuickInstallListCache);
        console.log(checkedIndex);

        if (checkedQuickInstallListCache[checkedIndex] && checkedQuickInstallListCache[checkedIndex].length > 0) {
            return checkedQuickInstallListCache[checkedIndex];
        }
        const list = quickInstallList.filter(item => {
            return checked.every(key => item.indexSet.has(key));
        });
        checkedQuickInstallListCache[checkedIndex] = list;
        console.log('checkedQuickInstallList end');
        console.log(quickInstallList);
        console.log(list);
        return list;
    })();
    console.log('set newDisabledSet');
    console.log(newDisabledSet);
    // 决定某一个group里哪些选项被禁用，是根据其他gorup选项选中状态决定
    // 遍历group，获取每一个group中选项的禁用状态
    quickInstallSelector.forEach((group) => {
        console.log('quickInstallSelector result');
        console.log(group);
        const thisGroupOptionKeys = group.list.map(item => item.optionKey);
        const thisGroupOptionKeysSet = new Set(thisGroupOptionKeys);
        // 除了自己group以外的选中状态
        const checkedExpectThisGroup = (() => {
            const checkedExpectThisGroupSet = new Set(checked);
            thisGroupOptionKeys.forEach((optionKey) => {
                checkedExpectThisGroupSet.delete(optionKey);
            });
            return Array.from(checkedExpectThisGroupSet);
        })();
        console.log('checkedExpectThisGroup result');
        console.log(checkedExpectThisGroup);
        // 对应的搜索结果，这里对搜索结果进行缓存以便提升运行速度
        const availableQuickInstallList = (() => {
            const checkedExpectThisGroupIndex = checkedExpectThisGroup.sort().join(',');
            if (checkedQuickInstallListCache[checkedExpectThisGroupIndex]) {
                return checkedQuickInstallListCache[checkedExpectThisGroupIndex];
            }
            const list = quickInstallList.filter(item => {
                return checkedExpectThisGroup.every(key => item.indexSet.has(key));
            });
            checkedQuickInstallListCache[checkedExpectThisGroupIndex] = list;
            return list;
        })();
        console.log('availableQuickInstallList result');
        console.log(availableQuickInstallList);
        const thisGroupEnableOptionKeysSet = new Set<string>();
        availableQuickInstallList.forEach((item) => {
            item.indexSet.forEach((key) => {
                if (thisGroupOptionKeysSet.has(key)) {
                    thisGroupEnableOptionKeysSet.add(key);
                }
            });
        });
        console.log('thisGroupEnableOptionKeysSet result');
        console.log(thisGroupEnableOptionKeysSet);
        thisGroupEnableOptionKeysSet.forEach((key) => {
            newDisabledSet.delete(key);
        });
    });
    // 判断当前选中状态，如果符合条件，则将cpu选项禁用
    const arraysAreEqual = (arr1: string[]) => {
        const selectArray = ['macos', 'nvidia', 'paddleversion', 'pip'];
        const targetArray = ['macos', 'linux', 'windows', 'otheros', 'nvidia', 'paddleversion', 'pip', '30version', 'develop'];
        const cudaArray = ['cuda117', 'cuda118', 'cuda120', 'cuda112', 'cuda116'];
        if (arr1.length !== selectArray.length + 1) {
          return false;
        }
      
        for (let i = 0; i < arr1.length; i++) {
          if (!targetArray.includes(arr1[i]) && !cudaArray.includes(arr1[i])) {
            return false;
          }
        }
      
        return true;
    }
    console.log('arraysAreEqual result');
    console.log(checked);
    console.log(arraysAreEqual(checked));
    if (arraysAreEqual(checked)) {
        newDisabledSet.delete('cpu'); 
    }

    // 芯片厂商保证始终可点
    Array.from(newDisabledSet).forEach((key) => {
        const chip = ['nvidia', 'kunlunxin', 'haiguang', 'shengteng', 'hanwuji', 'cpu'];
        if (chip.includes(key)) {
            newDisabledSet.delete(key);
        }
    });
    console.log('updateDisabledState result');
    console.log(newDisabledSet);
    console.log(Array.from(newDisabledSet));
    console.log(checkedQuickInstallList);
    return {
        disabled: Array.from(newDisabledSet),
        checkedItem: checkedQuickInstallList
    };
};

const useQuickInstallHooks = () => {
    const [suffixText, setSuffixText] = useState<string>('');
    const [quickInstallList, setQuickInstallList] = useState<IQuickInstallItem[]>([]);
    const [quickInstallSelector, setQuickInstallSelector] = useState<IQuickInstallSelectorGroup[]>([]);
    const quickInstallSelectorRef = useLatest(quickInstallSelector);
    // 选项对应的搜索结果的缓存，用来提高运行速度
    const quickInstallCacheRef = useRef<IQuickInstallListCache>({});
    // 当结果列表变化的时候就清空缓存
    useMemo(() => {
        quickInstallCacheRef.current = {};
        return quickInstallList;
    }, [quickInstallList]);

    const [checked, setChecked] = useState<string[]>(() => {
        let res = [];
        if (typeof (localStorage) === 'object') {
            const resJson = localStorage.getItem('quickInstallHooksChecked');
            if (resJson) {
                try {
                    const r = JSON.parse(resJson);
                    res = Array.isArray(r) ? r : [];
                }
                catch (err: any) {
                }
            }
        }
        return res;
    });

    const {
        disabled,
        checkedItem
    } = useMemo(() => {
        console.log('get updateDisabledState');
        console.log(checked);
        console.log(quickInstallSelector);
        return updateDisabledState(checked, quickInstallSelector, quickInstallList, quickInstallCacheRef.current);
    }, [checked, quickInstallSelector, quickInstallList]);

    const showItem = useMemo(() => {
        console.log('showItem');
        console.log(checked);
        console.log(quickInstallSelector);
        console.log(checkedItem);
        if (checkedItem && checkedItem.length > 0 &&
            checked.length === 1 &&
            ['kunlunxin', 'haiguang', 'shengteng', 'hanwuji', 'cpu', 'nvidia'].includes(checked[0])
        ) {
            return checkedItem[0];
        }
        if (checked.length >= quickInstallSelector.length) {
            return checkedItem[0];
        }
        if (checkedItem.length === 1) {
            return checkedItem[0];
        }
        return undefined;
    }, [checked, checkedItem, quickInstallSelector]);

    // 切换选项，更新展示内容
    const handleOnItemChange = useCallback((
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        groupKey: string,
        optionKey: string
    ): void => {
        setChecked(checked => {
            console.log('handleOnItemChange start');
            console.log(groupKey);
            console.log(optionKey);
            // console.log(checked);
            console.log(checked);
            const quickInstallSelector = quickInstallSelectorRef.current || [];
            let newChecked: string[] = checked;
            // 如果点击已经选中的选项，会取消选中状态
            if (checked.indexOf(optionKey) > -1) {
                newChecked = newChecked.filter(key => key !== optionKey);
            }
            else {
                // 如果是新选中，则会将同一组的其他key取消
                const groupList = quickInstallSelector.find(group => group.groupKey === groupKey)?.list ?? [];
                // 同一组的其他key
                const sameGroupOptionKey = groupList.map(option => option.optionKey);
                const sameGroupOptionKeySet = new Set(sameGroupOptionKey);
                newChecked = [
                    ...checked.filter(key => !sameGroupOptionKeySet.has(key)),
                    optionKey
                ];
            }
            // 切换飞桨版本也设置固定的默认选项
            if (groupKey === '飞桨版本' &&
                newChecked.length > 0
            ) {
                // console.log(quickInstallSelector);
                // console.log(newChecked);
                // const platform = quickInstallSelector.find(group => group.groupKey === '计算平台');
                // const platOptions = platform?.list;
                // if (platOptions && platOptions.length > 0) {
                //     const platformKey = platOptions.map(item => item.optionKey);

                //     platformKey.forEach(key => {
                //         if (newChecked.indexOf(key) !== -1) {
                //             newChecked.splice(newChecked.indexOf(key), 1);
                //         }
                //     });
                // }
                // newChecked = [...newChecked, optionKey];
                newChecked = [optionKey];
            }
            // 判断选择的是不是国产的芯片厂商
            // const isLocalInclude = ['kunlunxin', 'haiguang', 'shengteng', 'hanwuji'].includes(optionKey);
            // console.log('yingsi')
            // if (groupKey === '芯片厂商' &&
            //     isLocalInclude &&
            //     newChecked.length > 0
            // ) {
            //     newChecked = [optionKey];
            // }
            if (groupKey === '芯片厂商' &&
                optionKey === 'cpu' &&
                newChecked.length > 0
            ) {
                // console.log(quickInstallSelector);
                // console.log(newChecked);
                // const platform = quickInstallSelector.find(group => group.groupKey === '计算平台');
                // const platOptions = platform?.list;
                // if (platOptions && platOptions.length > 0) {
                //     const platformKey = platOptions.map(item => item.optionKey);

                //     platformKey.forEach(key => {
                //         if (newChecked.indexOf(key) !== -1) {
                //             newChecked.splice(newChecked.indexOf(key), 1);
                //         }
                //     });
                // }
                // newChecked = [...newChecked, optionKey];
                const cudaIndex = newChecked.findIndex(v => ['cuda117', 'cuda118', 'cuda120', 'cuda112', 'cuda116'].includes(v));
                console.log('get cuda index');
                console.log(cudaIndex);
                if (cudaIndex > -1) {
                    newChecked.splice(cudaIndex, 1);
                }
            }
            if (groupKey === '芯片厂商' &&
                newChecked.length > 0
            ) {
                console.log(quickInstallSelector);
                console.log(newChecked);
                const platform = quickInstallSelector.find(group => group.groupKey === '计算平台');
                const platOptions = platform?.list;
                if (platOptions && platOptions.length > 0) {
                    const platformKey = platOptions.map(item => item.optionKey);

                    platformKey.forEach(key => {
                        if (newChecked.indexOf(key) !== -1) {
                            newChecked.splice(newChecked.indexOf(key), 1);
                        }
                    });
                }
                // newChecked = [...newChecked, optionKey];
            }
            console.log('handleOnItemChange end');
            console.log(newChecked);
            return newChecked;
        });
    }, [setChecked, quickInstallSelectorRef]);

    useEffect(() => {
        if (typeof (localStorage) === 'object') {
            localStorage.setItem('quickInstallHooksChecked', JSON.stringify(checked));
        }
    }, [checked]);

    useEffect(() => {
        console.log('disabled changes');
        console.log(disabled);
        console.log(checkedItem);
        console.log(quickInstallCacheRef.current);
        console.log(quickInstallList);
        console.log(quickInstallSelector);
        // @ts-ignore
        setChecked(checked => {
            console.log('handleOnItemChange', checked);
            if (checkedItem && checkedItem.length > 0 && checked.length === 1 && ['30version', 'paddleversion', 'develop'].includes(checked[0])) {
                // let checkedItem = [...checked, 'linux', 'pip', 'nvidia', 'cuda120'];
                let checkedItem = [...checked, 'linux', ];
    
                if (['30version', 'develop'].includes(checked[0])) {
                    checkedItem = [...checked, 'linux',];
                }
                return checkedItem;
            }
            // if (checkedItem && checkedItem.length > 0 && checked.length === 1 && ['kunlunxin', 'haiguang', 'shengteng', 'hanwuji', 'cpu', 'nvidia'].includes(checked[0])) {
            // if (checkedItem && checkedItem.length > 0 && checked.length === 1 && ['kunlunxin', 'haiguang', 'shengteng', 'hanwuji'].includes(checked[0])) {
            if (checkedItem && checkedItem.length > 0 && checked.length === 1) {
                // 如果选中的是芯片厂商，则从上到下层层的过滤出，每一个集合的第一项可选内容
                const versionItems = (() => {
                    return quickInstallSelector[0].list.map(v => v.optionKey);
                })();
                console.log('versionSets');
                console.log(versionItems);
                const versionSets = (() => {
                    let totalSet: any[] = [];
    
                    versionItems.forEach((item, i) => {
                        totalSet[i] = [];
                        // @ts-ignore
                        checkedItem.forEach(v=> {
                            if (v.indexSet.has(item)) {
                                totalSet[i].push(v);
                            }
                        });
                    });
                    console.log('versionSets total');
                    console.log(totalSet);
                    return totalSet.find(set => set.length > 0);
                })();
                console.log('versionset');
                console.log(versionSets);
                const systemItems = (() => {
                    return quickInstallSelector[1].list.map(v => v.optionKey);
                })();
                const systemSets = (() => {
                    let totalSet: any[] = [];
    
                    systemItems.forEach((item, i) => {
                        totalSet[i] = [];
                        // @ts-ignore
                        versionSets.forEach(v => {
                            if (v.indexSet.has(item)) {
                                totalSet[i].push(v);
                            }
                        });
                    });
                    return totalSet.find(set => set.length > 0);
                })();
                console.log('systemSets');
                console.log(systemSets);
                const installItems = (() => {
                    return quickInstallSelector[2].list.map(v => v.optionKey);
                })();
                const installSets = (() => {
                    let totalSet: any[] = [];
    
                    installItems.forEach((item, i) => {
                        totalSet[i] = [];
                        // @ts-ignore
                        systemSets.forEach(v => {
                            if (v.indexSet.has(item)) {
                                totalSet[i].push(v);
                            }
                        });
                    });
                    return totalSet.find(set => set.length > 0);
                })();
                console.log('installSets');
                console.log(installSets);
                const platformItems = (() => {
                    return quickInstallSelector[4].list.map(v => v.optionKey);
                })();
                console.log('platformItems');
                console.log(platformItems);
                const platformSets = (() => {
                    let totalSet: any[] = [];
    
                    platformItems.forEach((item, i) => {
                        totalSet[i] = [];
                        // @ts-ignore
                        installSets.forEach(v => {
                            if (v.indexSet.has(item)) {
                                totalSet[i].push(v);
                            }
                        });
                    });
                    return totalSet.find(set => set.length > 0);
                })();
                console.log('platformSets');
                console.log(platformSets);
                if (Array.isArray(platformSets) && platformSets.length > 0) {
                    return Array.from(platformSets[0].indexSet);
                }
                // 针对没有计算平台匹配到的情况
                if (!Array.isArray(platformSets) &&
                    Array.isArray(installSets) && installSets.length === 1
                ) {
                    return Array.from(installSets[0].indexSet);
                }
            }
            return checked;
        });
    }, [disabled, checkedItem, quickInstallList, quickInstallSelector]);

    return {
        suffixText,
        quickInstallSelector,
        quickInstallList,
        checked,
        disabled,
        showItem,

        setSuffixText,
        setQuickInstallList,
        setQuickInstallSelector,
        setChecked,
        handleOnItemChange
    };
};

export default useQuickInstallHooks;
