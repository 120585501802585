/**
 * @file 首页 定制走马灯
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, { useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import classNames from 'classnames';
import { Carousel } from 'antd';
import { CarouselProps, CarouselRef } from 'antd/lib/carousel';

const PaddleCarousel = React.memo(
    React.forwardRef<CarouselRef, CarouselProps>((props, ref) => {
        const cRef = useRef<CarouselRef>(null);
        useImperativeHandle<CarouselRef | null, CarouselRef | null>(ref, () => cRef.current);

        const handleOnDotsMouseEnter = useCallback((index: number) => {
            if (cRef.current) {
                cRef.current.goTo(index, false);
            }
        }, []);

        const customPaging = useCallback(
            (index: number) => (
                <div onMouseEnter={() => handleOnDotsMouseEnter(index)} className="test">
                    <button>{index}</button>
                </div>
            ),
            [handleOnDotsMouseEnter]
        );
        
        //检查传入参数中包括slickGoTo值，直接指定轮播图index
        useEffect(() => {
            if (cRef.current && props.slickGoTo) {
                cRef.current.goTo(props.slickGoTo, false);
            }
        }, [props.slickGoTo]);

        return (
            <Carousel
                ref={cRef}
                customPaging={customPaging}
                pauseOnDotsHover={true}
                {...props}
                className={classNames(props.className, 'paddle-carousel')}
            />
        );
    })
);

export default PaddleCarousel;
