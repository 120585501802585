/**
 * @file 首页 运营位 item
 * @author FengGuang(fengguang01@baidu.com)
 */

import React from 'react';

import A from '../../../../components/a/A';

import {IOperatorGirdCarouselItem} from '../../types';
import VideoModal from '../../../../components/video-modal/VideoModal';

const OperatorCarouselItem: React.FC<IOperatorGirdCarouselItem> = props => {
    const renderChild = (
        <div className="paddle-operator-gird-carousel-item">
            <div
                className="paddle-operator-gird-carousel-background"
                style={{
                    backgroundImage: props.backgroundImage ? `url(${props.backgroundImage})` : undefined
                }}
            />
            <A
                className="paddle-operator-gird-carousel-link"
                href={props.type === 'video' ? '' : props.link}
                target={props.target}
            >
                <div className="paddle-operator-gird-carousel-content">
                    <div
                        className="paddle-operator-gird-carousel-title"
                        style={{
                            color: props.titleColor,
                            fontSize: props.titleSize
                        }}
                        dangerouslySetInnerHTML={{__html: props.title || ''}}
                    />
                    <div
                        className="paddle-operator-gird-carousel-text"
                        style={{
                            color: props.textColor,
                            fontSize: props.textSize
                        }}
                        dangerouslySetInnerHTML={{__html: props.text || ''}}
                    />
                    {!!props.btnText
                    && (
                        <div
                            className="paddle-operator-gird-carousel-other"
                            style={{color: props.textColor}}
                        >
                            <button
                                style={{
                                    color: props.textColor,
                                    borderColor: props.textColor
                                }}
                            >
                                {props.btnText}
                            </button>
                        </div>
                    )}
                </div>
            </A>
        </div>
    );

    return (
        props.type === 'video'
            ? (
                <VideoModal
                    src={props.link}
                >
                    {renderChild}
                </VideoModal>
            )
            : (
                <span>
                    {renderChild}
                </span>
            )
    );
};

export default OperatorCarouselItem;
