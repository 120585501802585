/**
 * @file 首页 运营位 item
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, {useCallback, useRef} from 'react';
import {Col} from 'antd';
import {
    RightOutlined
} from '@ant-design/icons';
import classNames from 'classnames';

import A from '../../../../components/a/A';
import VideoCover from '../../../../components/video-cover/VideoCover';
import VerticalEllipsis from '../../../../components/vertical-ellipsis/VerticalEllipsis';

import {IOperatorGirdItem} from '../../types';
import VideoModal from '../../../../components/video-modal/VideoModal';

const OperatorGirdItem: React.FC<IOperatorGirdItem> = props => {
    const videoCoverRef = useRef<HTMLVideoElement>(null);

    const onMouseEnter = useCallback(() => {
        if (videoCoverRef.current) {
            videoCoverRef.current.play();
        }
    }, []);

    const onMouseLeave = useCallback(() => {
        if (videoCoverRef.current) {
            videoCoverRef.current.pause();
        }
    }, []);

    const renderChild = (
        <div className={classNames(
            'paddle-operator-gird-item',
            {
                'paddle-operator-gird-item-video': props.backgroundVideo
            }
        )}>

            <div
                className="paddle-operator-gird-item-background"
                style={{
                    backgroundImage: props.backgroundImage ? `url(${props.backgroundImage})` : undefined
                }}
            >
                {!!props.backgroundVideo
                && (
                    <VideoCover
                        className="paddle-operator-gird-item-background-video"
                        src={props.backgroundVideo}
                        ref={videoCoverRef}
                        loop
                    />
                )}
            </div>
            <div
                className="paddle-operator-gird-item-title"
                title={props.title}
                style={{color: props.titleColor}}
            >
                {props.title}
                {props.type === 'video'
                && (
                    <i className="paddle-operator-gird-item-play-icon" />
                )}
            </div>
            <div className="paddle-operator-gird-item-text">
                <VerticalEllipsis
                    title={props.text}
                    html={props.text || ''}
                >
                </VerticalEllipsis>
            </div>
            <div
                className="paddle-operator-gird-item-extra"
                style={{color: props.tagTextColor}}
            >
                {props.tagText} {!!props.tagText && <RightOutlined />}
            </div>
        </div>
    );

    return (
        <Col
            xs={8}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {props.type === 'video'
                ? (
                    <VideoModal
                        src={props.link}
                    >
                        {renderChild}
                    </VideoModal>
                )
                : (
                    <A
                        href={props.link}
                        target={props.target}
                    >
                        {renderChild}
                    </A>
                )
            }
        </Col>
    );
};

export default OperatorGirdItem;
