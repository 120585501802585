/**
 * @file paddle 首页
 * @author FengGuang(fengguang01@baidu.com)
 */
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'location-origin';
import React from 'react';
import ReactDOM from 'react-dom';

import Index from './IndexIndex';

import './style.less';



ReactDOM.render(<Index />, document.getElementById('root'));


