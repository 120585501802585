/**
 * @file 产品全景
 * @author FengGuang(fengguang01@baidu.com)
 *
 */

import React, { Suspense, useEffect, useState } from 'react';
import { Button, Col, Row } from 'antd';
// import PaddleCarousel from '../paddle-carousel/PaddleCarousel';
import { INews } from '../../types';
import A from '../../../../components/a/A';
const PaddleCarousel = React.lazy(() => import('../paddle-carousel/PaddleCarousel'));

const News: React.FC<INews> = React.memo(props => {
    const { carousel, list } = props;
    // 添加滚动条监听事件,随着滚动判断轮播图是否出现在可视区域
    const [slickPause, setSlickPause] = useState<boolean>(false);
    useEffect(() => {
        const handleScroll = () => {
            // 可视窗口的高度
            const newsDom = document.querySelector('.paddle-news') as HTMLElement;
            const offsetTop = newsDom.offsetTop;
            const scrollTop = document.documentElement.scrollTop;
            const top = offsetTop - scrollTop;
            if (-295 < top && top < 766) {
                !slickPause && setSlickPause(true);
            } else {
                slickPause && setSlickPause(false);
            }
        };
        handleScroll();
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [slickPause]);
    // 记录屏幕划走前Slick值
    const [tempSlick, setTempSlicK] = useState<number>(0);
    // 设置下一次录播图展示时slickGoTo的位置
    const handleChange = (currentSlide: number) => {
        setTempSlicK(currentSlide + 1);
    };

    return (
        <Row className="paddle-news">
            <Col xs={12} className="paddle-news-carousel-wrap">
                <Suspense fallback={<div>Loading...</div>}>
                    {slickPause && 
                        <PaddleCarousel className="paddle-news-carousel" 
                                        autoplay={slickPause} 
                                        autoplaySpeed={4000} 
                                        slickGoTo={tempSlick} 
                                        afterChange={handleChange}>
                            {carousel.map(item => (
                            <div key={`${item.title}${item.timeText}${item.link}`}>
                                <div
                                    className="paddle-news-carousel-background"
                                    style={{
                                        backgroundImage: item.backgroundImage
                                            ? `url(${item.backgroundImage})`
                                            : undefined
                                    }}
                                />
                                <A
                                    href={item.link}
                                    target={item.target ? '_blank' : undefined}
                                    rel={item.target ? 'noopener noreferrer' : undefined}
                                >
                                    <div className="paddle-news-carousel-content">
                                        <div
                                            className="paddle-news-carousel-info"
                                            style={{ color: item.color ? item.color : undefined }}
                                        >
                                            <div
                                                className="paddle-news-carousel-time"
                                                // bca-disable-line 
                                                dangerouslySetInnerHTML={{ __html: item.timeText || '' }}
                                            />
                                            <div
                                                className="paddle-news-carousel-title"
                                                style={{ fontSize: item.titleSize }}
                                                // bca-disable-line 
                                                dangerouslySetInnerHTML={{ __html: item.title || '' }}
                                            />
                                            <div className="paddle-news-carousel-btns">
                                                {item.btns?.map(btn => (
                                                    <A
                                                        key={`${btn.title}${btn.link}`}
                                                        href={btn.link}
                                                        target={btn.target}
                                                    >
                                                        <Button className="paddle-news-carousel-btn">
                                                            {btn.title}
                                                        </Button>
                                                    </A>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </A>
                            </div>
                            ))}
                        </PaddleCarousel>
                    }   
                </Suspense>
            </Col>
            <Col xs={12} className="paddle-news-news-wrap">
                <div className="paddle-news-news-inner">
                    {list.map(item => (
                        <div key={`${item.title}${item.timeText}${item.link}`} className="paddle-news-news-item">
                            <A
                                href={item.link}
                                target={item.target ? '_blank' : undefined}
                                rel={item.target ? 'noopener noreferrer' : undefined}
                            >
                                <div className="paddle-news-news-item-time">{item.timeText}</div>
                                <div className="paddle-news-news-item-title" style={{ fontSize: item.titleSize }}>
                                    {item.title}
                                </div>
                            </A>
                        </div>
                    ))}
                </div>
            </Col>
        </Row>
    );
});

export default News;
