/**
 * @file paddle 首页 图标文字列表
 * @author FengGuang(fengguang01@baidu.com)
 */

import React from 'react';
import {Col} from 'antd';
import {IIconTextCardItem} from '../../types';
import A from '../../../../components/a/A';

interface IIconTextCardItemProps extends IIconTextCardItem {
    xs?: number | string;
}

const IconTextCardItem: React.FC<IIconTextCardItemProps> = props => {
    const xs = props.xs || 3;

    return (
        <Col xs={xs} className="paddle-index-icon-text-card-item-grid">
            <A
                className="paddle-index-icon-text-card-item"
                href={props.link}
                target={props.target ? '_blank' : undefined}
                rel={props.target ? 'noopener noreferrer' : undefined}
            >
                <i
                    className="paddle-index-icon-text-card-item-icon"
                    style={{backgroundImage: props.iconUrl ? `url(${props.iconUrl})` : undefined}}
                />
                <div className="paddle-index-icon-text-card-item-title">
                    {props.title}
                </div>
                <div className="paddle-index-icon-text-card-item-text">
                    {props.text}
                </div>
            </A>
        </Col>
    );
};

export default IconTextCardItem;
