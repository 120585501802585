/**
 * @file 带图的卡片
 * @author FengGuang(fengguang01@baidu.com)
 *
 */

import React, {useCallback, useRef, useState} from 'react';
import {Col} from 'antd';
import A from '../../../../components/a/A';
import VerticalEllipsis from '../../../../components/vertical-ellipsis/VerticalEllipsis';

export interface IPictureCard {
    title?: string;
    text?: string;
    imageUrl?: string;
    link?: string;
    target?: string | boolean;
    tagText?: string;
    extraTagText?: string;
}

interface IProps extends IPictureCard {
    children?: React.ReactNode;
}

const PictureTextCard: React.FC<IProps> = props => {
    const [textHeight, setTextHeight] = useState<string | number | undefined>();
    const textInnerRef = useRef<HTMLDivElement>(null);

    const {
        title,
        text,
        imageUrl,
        link,
        target,
        tagText,
        extraTagText
    } = props;

    const onMouseEnter = useCallback(() => {
        if (textInnerRef.current?.parentElement) {
            const parent = textInnerRef.current.parentElement;
            if (parent.offsetHeight < textInnerRef.current.offsetHeight) {
                setTextHeight(textInnerRef.current.offsetHeight);
            }
        }
    }, []);

    const onMouseLeave = useCallback(() => {
        setTextHeight(undefined);
    }, []);

    return (
        <Col
            className="paddle-application-card-grid"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            xs={8}
        >
            <div className="paddle-application-card">
                <A
                    className="paddle-application-card-full-link"
                    href={link}
                    target={target ? '_blank' : ''}
                    rel={target ? 'noopener noreferrer' : undefined}
                >
                    <div
                        className="paddle-application-card-img"
                        style={{backgroundImage: `url(${imageUrl})`}}
                    >
                        <img alt={title} src={imageUrl} />
                    </div>
                    <div className="paddle-application-card-contain">
                        <div className="paddle-application-card-title">
                            {title}
                        </div>
                        <div className="paddle-application-card-extra">
                            {!!extraTagText
                            && (
                                <span className="paddle-application-card-extra-tag">{extraTagText}</span>
                            )}
                        </div>
                        <div className="paddle-application-card-text">
                            <VerticalEllipsis
                                className="paddle-application-card-text-inner"
                                style={{
                                    height: textHeight
                                }}
                                innerRef={textInnerRef}
                                delay={200}
                            >
                                {text}
                            </VerticalEllipsis>
                        </div>
                        <div className="paddle-application-card-tag-group">
                            <span className="paddle-application-card-tag">
                                {tagText} {'>'}
                            </span>
                        </div>
                    </div>
                </A>
            </div>
        </Col>
    );
};

export default PictureTextCard;
