/**
 * @file 项目实践
 * @author FengGuang(fengguang01@baidu.com)
 *
 */

import React from 'react';
import classNames from 'classnames';
import {Row} from 'antd';

import PictureTextCard from './PictureTextCard';
import {IPictureTextCardItem} from '../../types.d';

interface IProps {
    className?: string;
    list?: IPictureTextCardItem[];
}

const PictureTextCardGroup: React.FC<IProps> = React.memo(props => {
    const className = props.className;
    const list = props.list || [];

    return (
        <div
            className={classNames(
                'paddle-application-card-group-wrap',
                className
            )}
        >
            <div className="paddle-application-card-group">
                <Row>
                    {list.map(item => (
                        <PictureTextCard
                            key={`${item.title},${item.text}`}
                            {...item}
                        />
                    ))}
                </Row>
            </div>
        </div>
    );
});

export default PictureTextCardGroup;
