/**
 * @file paddle 首页
 * @author FengGuang(fengguang01@baidu.com)
 */

import {useState, useEffect} from 'react';
import sleepPromise from '../../utils/sleep-promise';

import useQuickStartStore from './components/quick-start/quickStartHooks';

import { getOsType } from './../../utils/osType';

import {
    fetchGetIndexData
} from '../../api/pages/indexApi';

import {
    IIconTextList,
    IOperatorGird,
    IVideoList,
    IIconTextCard,
    IPictureTextCard,
    IProductList,
    IPartner,
    INews,
    // ITimeline,
    IQuickInstall
} from './types.d';

// 预请求
let preRequest: any = {
    fetchGetIndexData: fetchGetIndexData()
};

const useIndexStore = () => {

    const [operatorGird, setOperatorGird] = useState<IOperatorGird>();
    const [iconTextList, setIconTextList] = useState<IIconTextList>();
    const quickStartHooks = useQuickStartStore();
    const {setQuickInstallSelector, setQuickInstallList} = quickStartHooks;
    const setQuickInstallChecked = quickStartHooks.setChecked;
    const setQuickInstallSuffixText = quickStartHooks.setSuffixText;
    const [quickInstall, setQuickInstall] = useState<IQuickInstall>();
    const [pictureTextCard, setPictureTextCard] = useState<IPictureTextCard>();
    const [productList, setProductList] = useState<IProductList>();
    const [news, setNews] = useState<INews>();
    const [iconTextCard, setIconTextCard] = useState<IIconTextCard>();
    const [videoList, setVideoList] = useState<IVideoList>();
    const [partner, setPartner] = useState<IPartner>();

    useEffect(() => {
        (async () => {
            const fetchFunc = preRequest.fetchGetIndexData || fetchGetIndexData();
            delete preRequest.fetchGetIndexData;

            const res = await fetchFunc
                .catch(() => {
                    // ignore
                });
            if (res) {
                const pageData = res.body.result?.pageData || {};
                const og: any = pageData.operatorGird || {};
                const ogItems: any[] = Array.isArray(og.items) ? og.items : [];
                const ogCarousel: any[] = Array.isArray(og.carousel) ? og.carousel : [];
                setOperatorGird({
                    carousel: ogCarousel.map(item => ({
                        title: item.title,
                        titleColor: item.titleColor,
                        titleSize: item.titleSize,
                        text: item.text,
                        textColor: item.textColor,
                        textSize: item.textSize,
                        type: item.type,
                        btnText: item.btnText,
                        btnTextColor: item.btnTextColor,
                        backgroundImage: item.backgroundImage,
                        backgroundVideo: item.backgroundVideo,
                        link: item.link,
                        target: !!item.target
                    })),
                    items: ogItems.map(item => ({
                        title: item.title,
                        titleColor: item.titleColor,
                        text: item.text,
                        textColor: item.textColor,
                        type: item.type,
                        backgroundImage: item.backgroundImage,
                        backgroundVideo: item.backgroundVideo,
                        link: item.link,
                        target: !!item.target,
                        tagText: item.tagText,
                        tagTextColor: item.tagTextColor
                    }))
                });

                // 中断一下减少未响应时间
                await sleepPromise(0);

                const itl: any = pageData.iconTextList || {};
                const itlList: any[] = Array.isArray(itl.list) ? itl.list : [];
                setIconTextList({
                    title: itl.title,
                    subtitle: itl.subtitle,
                    list: itlList.map(item => ({
                        title: item.title,
                        text: item.text,
                        iconUrl: item.iconUrl,
                        link: item.link,
                        target: !!item.target
                    }))
                });

                const qi: any = pageData.quickInstall || {};
                const qiGroup: any[] = Array.isArray(qi.group) ? qi.group : [];
                const qiList: any[] = Array.isArray(qi.list) ? qi.list : [];
                setQuickInstall({
                    title: qi.title,
                    subtitle: qi.subtitle
                });
                const resQiGroup = qiGroup.map(groupItem => {
                    const list: any[] = Array.isArray(groupItem.list) ? groupItem.list : [];
                    return {
                        groupName: groupItem.groupName,
                        groupKey: groupItem.groupKey || groupItem.groupName,
                        disabledTooltip: groupItem.disabledTooltip,
                        tips: groupItem.tips,
                        list: list.map(item => ({
                            id: `${groupItem.groupName}${item.optionName}${item.optionKey}`,
                            groupName: groupItem.groupName,
                            groupKey: groupItem.groupKey || groupItem.groupName,
                            optionName: item.optionName,
                            optionKey: item.optionKey
                        }))
                    };
                });
                setQuickInstallSelector(resQiGroup);
                const resQIList = qiList.map(item => {
                    const context: any[] = Array.isArray(item.context) ? item.context : [];
                    return {
                        index: item.index,
                        indexSet: typeof (item.index) === 'string' ? new Set<string>(item.index.split(',')) : new Set<string>(),
                        context: context.map(contextItem => ({
                            title: contextItem.title,
                            text: contextItem.text
                        }))
                    };
                });

                setQuickInstallList(resQIList);
                setQuickInstallSuffixText(qi.suffixText || '');

                // 设置快速安装默认选中的项目
                const currentOs = getOsType();
                
                const defaultOs = (() => {
                    if (currentOs === 'macos') {
                        return ((qi.defaultSelectedKey as string).replace(/cuda102/, 'cpu').replace(/linux/, 'macos'))
                    }
                    if (currentOs === 'windows') {
                        return ((qi.defaultSelectedKey as string).replace(/linux/, 'windows'));
                    }
                    return '';
                })();
                const qiDefaultCheckedStr = defaultOs || qi.defaultSelectedKey || '';
                const qiDefaultChecked = qiDefaultCheckedStr.split(',');
                
                // 剔除列表中没有但是默认选中里有的key值
                const existKey = new Set();
                resQiGroup.forEach((group) => {
                    group.list.forEach((item) => {
                        existKey.add(item.optionKey);
                    });
                });
                setQuickInstallChecked((old) => {
                    const def = old.filter(k => existKey.has(k));
                    return (
                        old.length > 0 ? def : qiDefaultChecked
                    );
                });

                // 中断一下减少未响应时间
                await sleepPromise(0);

                const ptc: any = pageData.pictureTextCard || {};
                const ptcList: any[] = Array.isArray(ptc.list) ? ptc.list : [];
                const ptcCheckAll: any = ptc.checkAll;
                setPictureTextCard({
                    title: ptc.title,
                    subtitle: ptc.subtitle,
                    checkAll: ptcCheckAll
                        ? {
                            text: ptcCheckAll.text,
                            link: ptcCheckAll.link,
                            target: !!ptcCheckAll.target
                        }
                        : undefined,
                    list: ptcList.map(item => ({
                        title: item.title,
                        text: item.text,
                        imageUrl: item.imageUrl,
                        link: item.link,
                        target: item.target,
                        tagText: item.tagText,
                        extraTagText: item.extraTagText
                    }))
                });

                const pl: any = pageData.productList || {};
                const plGroup: any[] = Array.isArray(pl.group) ? pl.group : [];
                const plCheckAll: any = pl.checkAll;
                setProductList({
                    title: pl.title,
                    subtitle: pl.subtitle,
                    checkAll: plCheckAll
                        ? {
                            text: plCheckAll.text,
                            link: plCheckAll.link,
                            target: !!plCheckAll.target
                        }
                        : undefined,
                    group: plGroup.map(item => {
                        const list: any[] = Array.isArray(item.list) ? item.list : [];
                        return {
                            title: item.title,
                            list: list.map((subitem) => {
                                const tags: any[] = Array.isArray(subitem.tags) ? subitem.tags : [];
                                return {
                                    title: subitem.title,
                                    text: subitem.text,
                                    link: subitem.link,
                                    target: !!subitem.target,
                                    tags: tags.map((tag) => ({
                                        text: tag.text,
                                        color: tag.color,
                                        type: tag.type
                                    }))
                                };
                            })
                        };
                    })
                });

                const news: any = pageData.news || {};
                const newsCheckAll: any = news.checkAll;
                const newsCarousel: any[] = Array.isArray(news.carousel) ? news.carousel : [];
                const newsList: any[] = Array.isArray(news.list) ? news.list : [];
                setNews({
                    title: news.title,
                    subtitle: news.subtitle,
                    checkAll: newsCheckAll
                        ? {
                            text: newsCheckAll.text,
                            link: newsCheckAll.link,
                            target: !!newsCheckAll.target
                        }
                        : undefined,
                    carousel: newsCarousel.map(item => {
                        const btns: any[] = Array.isArray(item.btns) ? item.btns : [];
                        return {
                            title: item.title,
                            titleSize: item.titleSize,
                            timeText: item.timeText,
                            link: item.link,
                            target: item.target,
                            backgroundImage: item.backgroundImage,
                            color: item.color,
                            btns: btns.map(btn => ({
                                title: btn.title,
                                link: btn.link,
                                target: !!btn.target
                            }))
                        };
                    }),
                    list: newsList.map(item => ({
                        title: item.title,
                        titleSize: item.titleSize,
                        timeText: item.timeText,
                        link: item.link,
                        target: item.target
                    }))
                });

                const itc: any = pageData.iconTextCard || {};
                const itcList: any[] = Array.isArray(itc.list) ? itc.list : [];
                setIconTextCard({
                    title: itc.title,
                    subtitle: itc.subtitle,
                    list: itcList.map(item => ({
                        title: item.title,
                        text: item.text,
                        iconUrl: item.iconUrl,
                        link: item.link,
                        target: !!item.target
                    }))
                });

                const vl: any = pageData.videoList || {};
                const vlList: any[] = Array.isArray(vl.list) ? vl.list : [];
                setVideoList({
                    title: vl.title,
                    subtitle: vl.subtitle,
                    list: vlList.map(item => ({
                        title: item.title,
                        titleColor: item.titleColor,
                        backgroundImage: item.backgroundImage,
                        backgroundVideo: item.backgroundVideo,
                        tagText: item.tagText,
                        tagTextColor: item.tagTextColor,
                        link: item.link,
                        target: !!item.target
                    }))
                });

                const pa: any = pageData.partner || {};
                const paTextCard: any[] = Array.isArray(pa.textCard) ? pa.textCard : [];
                const paList: any[] = Array.isArray(pa.list) ? pa.list : [];
                const paCheckAll: any = pa.checkAll;
                setPartner({
                    title: pa.title,
                    subtitle: pa.subtitle,
                    checkAll: paCheckAll
                        ? {
                            text: paCheckAll.text,
                            link: paCheckAll.link,
                            target: !!paCheckAll.target
                        }
                        : undefined,
                    textCard: paTextCard.map(item => ({
                        title: item.title,
                        text: item.text
                    })),
                    list: paList.map(item => ({
                        title: item.title,
                        text: item.text,
                        imgUrl: item.imgUrl,
                        link: item.link,
                        target: !!item.target
                    }))
                });
            }
        })();
    }, [setQuickInstallList, setQuickInstallSelector, setQuickInstallChecked, setQuickInstallSuffixText]);

    return {
        operatorGird,
        iconTextList,
        quickInstall,
        pictureTextCard,
        iconTextCard,
        productList,
        news,
        videoList,
        partner,
        // timeline,

        quickStartHooks
    };
};

export default useIndexStore;
