/**
 * @file paddle 首页 视频列表
 * @author FengGuang(fengguang01@baidu.com)
 */
import React from 'react';
import {Row} from 'antd';
import VideoListItem from './VideoListItem';
import {IVideoList} from '../../types';

const VideoList: React.FC<IVideoList> = React.memo(props => {
    return (
        <Row gutter={20} className="paddle-index-video-list">
            {props.list.map(item => (
                <VideoListItem key={`${item.title}${item.link}`} {...item} />
            ))}
        </Row>
    );
});

export default VideoList;
