/**
 * @file 产品全景
 * @author FengGuang(fengguang01@baidu.com)
 *
 */

import React from 'react';
import {Row} from 'antd';
import {IProductList} from '../../types';
import ProductListItem from './ProductListItem';

const ProductList: React.FC<IProductList> = React.memo(props => {
    return (
        <div className="paddle-index-product-list">
            {props.group.map(listItem => (
                <div key={listItem.title} className="paddle-index-product-list-group">
                    <div className="paddle-index-product-list-group-title">
                        <span>{listItem.title}</span>
                    </div>
                    <div className="paddle-index-product-list-group-list">
                        <Row>
                            {listItem.list.map(product => (
                                <ProductListItem key={`${product.title}${product.text}`} {...product} />
                            ))}
                        </Row>
                    </div>
                </div>
            ))}
        </div>
    );
});

export default ProductList;
