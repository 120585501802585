/**
 * @file paddle 首页 图标文字列表 在线体验
 * @author FengGuang(fengguang01@baidu.com)
 */

import React from 'react';
import {IIconTextList} from '../../types';
import IconTextListItem from './IconTextListItem';

const IconTextList: React.FC<IIconTextList> = React.memo(props => {
    return (
        <div className="paddle-index-icon-text-list">
            {props.list.map(item => (
                <IconTextListItem key={`${item.title}${item.text}`}{...item} />
            ))}
        </div>
    );
});

export default IconTextList;
